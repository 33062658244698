import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { GroupByPipe } from '@z-trippete/angular-pipes';

import { StatsProductionStoreEffects } from './effects';
import {
  StatsProductionReducer,
  statsProductionReducerFactory,
} from './reducer';
import { State } from './state';

export const STATS_PRODUCTION_REDUCER_TOKEN = new InjectionToken<
  ActionReducer<Map<string, State>>
>('Stats production state reducer');

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('statsProduction', STATS_PRODUCTION_REDUCER_TOKEN),
    EffectsModule.forFeature([StatsProductionStoreEffects]),
  ],
  providers: [
    StatsProductionReducer,
    {
      provide: STATS_PRODUCTION_REDUCER_TOKEN,
      deps: [StatsProductionReducer],
      useFactory: statsProductionReducerFactory,
    },
    GroupByPipe,
  ],
})
export class StatsProductionStoreModule {}
