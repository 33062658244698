import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CollectedType, StatsFlat } from '../../models';
import { StatsComparePeriod } from '../../models/objects/stats-compare-period';

import { featureAdapter, State } from './state';

export const getTotalsRow = (state: State) => state.totalsRow;

export const getSplitIsLoading = (state: State) => state.splitLoading;

export const getTotalIsLoading = (state: State) => state.totalLoading;

export const getError = (state: State) => state.error;

export const getInterruptCalls = (state: State) => state.interruptCalls;

export const getAddonCategories = (state: State) => state.addonsCategories;

export const getCompareOptions = (state: State) => state.compareOptions;

export const getTypeOptions = (state: State) => state.typeOptions;

export const getCollectedOptions = (state: State) => state.collectedOptions;

export const getSelectedColumns = (state: State) => state.selectedColumns;

export const getIsLoading = (state: State) => state.isLoading;

export const getIsLoadingTableauNumbers = (state: State) =>
  state.isLoadingTableauNumbers;

export const getStats = (state: State) => state.stats;

export const getFiltersHeaderValues = (state: State) =>
  state.filtersHeaderValues;

export const getFiltersOptionsByProperties = (state: State) =>
  state.filtersOptionsByProperties;

export const getFiltersOptions = (state: State) => state.filtersOptions;

export const getChartData = (state: State) => state.chart;

export const getEnableValueChangeFilters = (state: State): boolean =>
  state.enableValueChangeFilters;

export const getComparePeriosOptions = (state: State): string[] =>
  state?.comparePeriod?.comparePeriodOptions;

export const getMinPropertyActivationDate = (state: State): Date =>
  state?.comparePeriod?.minPropertyActivationDate;

export const getComparePeriod = (state: State): StatsComparePeriod =>
  state.comparePeriod;

export const getPropertiesSelectedIds = (state: State): number[] =>
  state.propertiesSelectedIds;

export const getAccommodationRowsToLoad = (state: State): StatsFlat[] =>
  state?.tableauNumbers?.accommodationRowsToLoad;

export const getColumnsLoaded = (state: State): CollectedType[] =>
  state?.columnsLoaded;

export const getExportId = (state: State) => state.exportId;

export const getMinDate = (state: State) => state.min_date;

export const getSettings = (state: State) => state.settings;

export const selectState = createFeatureSelector<State>('statsProduction');

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectStatsList: (state: object) => StatsFlat[] =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectIsLoadingTableauNumers = createSelector(
  selectState,
  getIsLoadingTableauNumbers,
);

export const selectStats = createSelector(selectState, getStats);

export const selectAccommodationRowsToLoad = createSelector(
  selectState,
  getAccommodationRowsToLoad,
);

export const selectPropertiesSelectedIds = createSelector(
  selectState,
  getPropertiesSelectedIds,
);

export const selectFiltersHeaderValues = createSelector(
  selectState,
  getFiltersHeaderValues,
);

export const selectFiltersOptionsByProperties = createSelector(
  selectState,
  getFiltersOptionsByProperties,
);

export const selectFiltersOptios = createSelector(
  selectState,
  getFiltersOptions,
);

export const selectChartData = createSelector(selectState, getChartData);

export const selectEnableValueChangeFilters = createSelector(
  selectState,
  getEnableValueChangeFilters,
);

export const selectComparePeriodOptions = createSelector(
  selectState,
  getComparePeriosOptions,
);

export const selectMinPropertyActivationDate = createSelector(
  selectState,
  getMinPropertyActivationDate,
);

export const selectComparePeriod = createSelector(
  selectState,
  getComparePeriod,
);

export const selectColumnsLoaded = createSelector(
  selectState,
  getColumnsLoaded,
);

export const selectSelectedColumns = createSelector(
  selectState,
  getSelectedColumns,
);

export const selectCompareOptions = createSelector(
  selectState,
  getCompareOptions,
);

export const selectTypeOptions = createSelector(selectState, getTypeOptions);

export const selectCollectedOptions = createSelector(
  selectState,
  getCollectedOptions,
);

export const selectAddonCategories = createSelector(
  selectState,
  getAddonCategories,
);

export const selectMinDate = createSelector(selectState, getMinDate);

export const selectSettings = createSelector(selectState, getSettings);

export const selectSplitIsLoading = createSelector(
  selectState,
  getSplitIsLoading,
);

export const selectTotalIsLoading = createSelector(
  selectState,
  getTotalIsLoading,
);

export const selectInterruptCalls = createSelector(
  selectState,
  getInterruptCalls,
);

export const selectTotalsRow = createSelector(selectState, getTotalsRow);

export const selectExportId = createSelector(selectState, getExportId);
