import { createAction, props } from '@ngrx/store';

import { IProperty } from '../../features/commons/properties/models/property.model';
import {
  CollectedType,
  ExportWidgetData,
  StatsFiltersResponse,
  StatsFlat,
  StatsForm,
  StatsRequest,
  StatsResponse,
  StatsSettings,
} from '../../models';
import { Hooks } from '@app/helpers';

export const clearTotals = createAction('[Stats Production]  Remove Chart');

export const clearAllRows = createAction('[Stats Production]  Remove All Rows');

export const clearChart = createAction('[Stats Production]  Remove Chart');

export const addSplittedResponse = createAction(
  '[Stats Production] add splitted Response',
  props<{
    stats: Partial<StatsResponse>;
    translations: {
      currentYear: string;
      lastYear: string;
      total: string;
    };
    reservation_date_from: string;
    reservation_date_to: string;
  }>(),
);

export const loadSplittedRequest = createAction(
  '[Stats Production] Load splitted Request',
  props<{
    data: Partial<StatsRequest>;
    translations: {
      currentYear: string;
      lastYear: string;
      total: string;
    };
    hooks?: Hooks;
  }>(),
);

export const loadSplittedSuccess = createAction(
  '[Stats Production] Load splitted Success',
);

export const loadSplittedFailure = createAction(
  '[Stats Production] Load splitted Failure',
  props<{ error: any }>(),
);

export const load = createAction('[Stats Production] load');

export const setSettingsRequest = createAction(
  '[Stats Production] Add Settings Request',
  props<{
    settings: StatsSettings[];
  }>(),
);

export const setSettingsSuccess = createAction(
  '[Stats Production] Add SettingsSuccess',
  props<{
    settings: StatsSettings[];
  }>(),
);

export const setSelectedColumnsRequest = createAction(
  '[Stats Production] Init Selected Columns',
  props<{
    tableKey: string;
  }>(),
);

export const setSelectedColumnsSuccess = createAction(
  '[Stats Production] Init Selected Success',
  props<{
    selectedColumns: string[];
    settings?: StatsSettings[];
  }>(),
);

export const changePropertiesTab = createAction(
  '[Stats Production] Change Properties Tab',
  props<{
    filtersHeaderValues: Partial<StatsForm>;
    propertiesSelectedIds: number[];
  }>(),
);

export const SetAccommodationRowsInViewport = createAction(
  '[Stats Production] Set Accommodation Rows In Viewport',
  props<{
    accommodationRowsInViewport: StatsFlat[];
  }>(),
);

export const LoadAccommodationTableauNumberRequest = createAction(
  '[Stats Production] Load Accommodation Tableau Number Request',
  props<{
    data: Partial<StatsRequest>[];
    tableauNumbersToLoad: { parent: StatsFlat; child: StatsFlat }[];
  }>(),
);

export const LoadAccommodationTableauNumberSuccess = createAction(
  '[Stats Production] Load Accommodation Tableau Number Success',
  props<{
    tableauNumbers: Partial<StatsResponse>[];
    tableauNumbersToLoad: { parent: StatsFlat; child: StatsFlat }[];
  }>(),
);

export const LoadAccommodationTableauNumberFailure = createAction(
  '[Stats Production] Load Accommodation Tableau Number Failure',
  props<{ error: any }>(),
);

export const buildComparePeriodOptions = createAction(
  '[Stats Production] Build Compare Period Options',
  props<{
    min_date?: string;
  }>(),
);

export const setEnableValueChangeFilters = createAction(
  '[Stats Production] Set enable Value Change Filters',
);

export const setChartTypeSelected = createAction(
  '[Stats Production] Set Chart Revenue Type Selected',
  props<{
    chartTypeSelected: CollectedType;
    translations: { currentYear: string; lastYear: string };
  }>(),
);

export const buildFiltersOptions = createAction(
  '[Stats Production] Build Filters Options',
  props<{
    filtersHeaderValues?: Partial<StatsForm>;
    setInHeaderFiltersValue: boolean;
  }>(),
);

export const buildChart = createAction(
  '[Stats Production] Build Chart',
  props<{
    translations: { currentYear: string; lastYear: string };
  }>(),
);

export const setProperties = createAction(
  '[Stats Production] Set Properties',
  props<{ properties: IProperty[] }>(),
);

export const setPropertiesSelected = createAction(
  '[Stats Production] Set Properties Selected',
  props<{ propertiesSelectedIds: number[] }>(),
);

export const loadFiltersRequest = createAction(
  '[Stats Production] Load Filters Request',
  props<{
    propertyIds: number[];
  }>(),
);

export const loadFiltersSuccess = createAction(
  '[Stats Production] Load Filters Success',
  props<{
    filters: StatsFiltersResponse;
  }>(),
);

export const loadFiltersFailure = createAction(
  '[Stats Production] Load Filters Failure',
  props<{ error: any }>(),
);

export const setFiltersHeaderValues = createAction(
  '[Stats Production] Set Filters Data Success',
  props<{ filtersHeaderValues: Partial<StatsForm> }>(),
);

export const loadMinDateRequest = createAction(
  '[Stats Production] Load Min Date Request',
  props<{
    propertyIds: number[];
  }>(),
);

export const loadMinDateSuccess = createAction(
  '[Stats Production] Load Min Date Success',
  props<{
    min_date: string;
  }>(),
);

export const loadMinDateFailure = createAction(
  '[Stats Production] Load Min Date Failure',
  props<{ error: any }>(),
);

export const loadRequest = createAction(
  '[Stats Production] Load Request',
  props<{
    data: Partial<StatsRequest>;
    translations: {
      currentYear: string;
      lastYear: string;
      total: string;
    };
    hooks?: Hooks;
  }>(),
);

export const loadSuccess = createAction(
  '[Stats Production] Load Success',
  props<{
    stats: StatsResponse;
    translations: {
      currentYear: string;
      lastYear: string;
      total: string;
    };
  }>(),
);

export const loadFailure = createAction(
  '[Stats Production] Load Failure',
  props<{ error: any }>(),
);

export const exportRequest = createAction(
  '[Stats Production] Export Request',
  props<{ data: Partial<StatsRequest>; exportData: ExportWidgetData }>(),
);

export const exportSuccess = createAction(
  '[Stats Production] Export Success',
  props<{ exportId: number }>(),
);

export const exportFailure = createAction(
  '[Stats Production] Export Failure',
  props<{ error: any }>(),
);

export const setInterruptCalls = createAction(
  '[Stats Production] Set Interrupt Calls',
  props<{ stop: boolean }>(),
);

export const resetState = createAction('[Stats Production] Reset State');
